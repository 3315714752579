@import './variables.scss';

html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
  color: $text;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// base tags

.btn {
  padding: 10px;
  font-size: 16px;
  border: 0px;
  cursor: pointer;

  &.blue {
    color: $white;
    background-color: $blue;
  }

  &.red {
    color: $white;
    background-color: $error;
  }

  &.outline {
    background-color: transparent;
    border: 1px solid $button-border;
  }

  &.full {
    width: 100%;
  }

  &.gray {
    color: $text;
    background-color: $disabled;
  }

  &:disabled {
    color: $text;
    background-color: $disabled;
    border: 1px solid $button-border;
  }
}

label {
  margin-bottom: 10px;

  select, textarea, input {
    margin-top: 10px;
  }

  .sub-label {
    margin-top: 5px;
    font-size: 12px;
  }
}

input {
  padding: 10px;
  margin-bottom: 25px;
  font-size: 1rem;
  border: 1px solid $border;
  display: block;

  &[type="checkbox"] {
    display: inline;
    margin-left: 10px;
  }

  &[type="text"], &[type="email"], &[type="password"] {
    width: 100%;
  }

  &.error {
    border: 1px solid $error;
  }
}

textarea {
  width: 100%;
  height: 130px;
  padding: 10px;
  margin-bottom: 25px;
  font-size: 1rem;
  border: 1px solid $border;
  display: block;

  &.error {
    border: 1px solid $error;
  }
}

select {
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px;
  line-height: 1.3;
  margin-bottom: 25px;
  font-size: 1rem;
  border: 1px solid $border;
  display: block;

  &.error {
    border: 1px solid $error;
  }
}

a { text-decoration: none; }

// Structure
#root { height: 100%;}
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page-content {
  flex: 1 0 auto;
}

.container {
  max-width: 980px;
  margin: 0 auto;
  padding: 15px;
}

.full-page {
  padding: 15px;
}

.page-header {
  text-align: center;
  font-weight: bold;
}

// Errors

.error-output {
  font-size: 12px;
  color: $error;
}

.error-block {
  color: $error;
  text-align: center;
  padding: 15px;
}

.success-block {
  color: $success;
  text-align: center;
  padding: 15px;
}