@import '../../styles/variables';

.pageHeader {
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  padding: 25px 15px;
  margin-bottom: 25px;
  background-color: $cta-color;
  color: $white;

  .split {
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;

    .employees, .employers {
      text-align: left;
    }

    ul {
      list-style-type: none;
      padding: 0;
      font-size: 16px;

      li { margin-bottom: 10px; }
    }
  }

  .register {
    margin-top: 15px;
  }

  .special {
    margin-top: 15px;
  }
}

.sticky {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.recent {
  font-size: 18px;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 15px;
}

.jobs {
  margin-bottom: 5px;
}

.moreJobs {
  margin-top: 25px;
}

@media (max-width: 620px) {
  .pageHeader .split {
    flex-direction: column;
    align-items: center;

    .employees { margin-bottom: 25px; }
  }
}