@import '../../styles/variables';

.header {
  padding: 15px;
  border-bottom: 1px solid $border;
  box-shadow: 0px 1px 4px $border;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    font-weight: bold;
    a { color: $text; }
  }

  .controls {
    button {
      margin-right: 15px;

      &:last-child { margin: 0; }
    }
  }
}

@media (max-width: 480px) {
  .header {
    flex-direction: column;

    .logo { margin-bottom: 25px; }
  }
}