@import '../../styles/variables';

.jobDetail {
  padding: 15px;
  border: 1px solid $job-border;

  .jobHeader {
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .jobInfo {
    margin-bottom: 25px;
    overflow: hidden;
    white-space: pre-line;

    .sectionHeader {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .remote {
      font-size: 12px;
    }
  }

  .application {
    margin-top: 25px;

    .applicationHeader {
      text-align: center;
      font-weight: bold;
    }

    .howToApply {
      margin: 20px 0;
      white-space: pre-line;
    }
  }
}