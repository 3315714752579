@import '../../styles/variables';

.job {
  padding: 10px;
  border: 1px solid $job-border;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 980px;
  background-color: $background;
  cursor: pointer;

  &.highlighted { background-color: $highlight-color; }

  .titles {
    width: 35%;

    .position { font-weight: bold; margin-bottom: 5px; }
    .companyName { font-size: 1rem; }
  }

  .tags {
    width: 35%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .tag {
      padding: 2px 5px;
      margin: 5px 5px 5px 0;
      font-weight: bold;
      font-size: 0.875rem;
      background-color: $tag-background;
      border: 1px solid $tag-border;
      word-break: break-all;

      &:last-child { margin-right: 0; }
    }
  }

  .timestamps {
    width: 20%;
    text-align: center;

    .location { margin-top: 5px; }
    .remote { font-size: 12px; }
  }

  .apply {
    width: 10%;
    text-align: right;
  }
}

@media (max-width: 750px) {
  .job {
    flex-direction: row;
    flex-wrap: wrap;

    .titles {
      width: 50%;
      text-align: center;
      margin-bottom: 15px;
      order: 1;
    }

    .tags {
      width: 50%;
      justify-content: center;
      margin-bottom: 15px;
      order: 3;
    }

    .timestamps {
      width: 50%;
      margin-bottom: 15px;
      order: 2;
    }

    .apply {
      width: 50%;
      text-align: center;
      order: 4;
    }
  }
}

@media (max-width: 420px) {
  .job {
    flex-direction: column;

    .titles {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
      order: 1;
    }

    .tags {
      width: 100%;
      justify-content: center;
      margin-bottom: 15px;
      order: 2;
    }

    .timestamps {
      width: 100%;
      margin-bottom: 15px;
      order: 3;
    }

    .apply {
      width: 100%;
      text-align: center;
      order: 4;
    }
  }
}