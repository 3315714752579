.switchInput {
  margin-bottom: 25px;

  .inputHeader {
    margin-bottom: 10px;
  }

  .buttonsContainer {
    max-width: 100%;
    display: flex;

    .split {
      width: 50%;
    }
  }
}
