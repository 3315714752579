@import '../../styles/variables';

.split {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;

  .half {
    padding: 10px;
    width: 50%;
    border-right: 1px solid $comp-border;

    &:last-child { border: 0; }
  }
}

.sticky {
  position: sticky;
  top: 0;
}

@media (max-width: 980px) {
  .split {
    flex-direction: column;
    align-items: center;

    .half {
      border: 0;
      width: 100%;
    }
  }
}