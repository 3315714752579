@import '../../styles/variables';

.modal {

  .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $modal-background;

  }

  .content {
    position: fixed;
    max-width: 980px;
    margin: auto;
    background: $background;
    top: 50px;
    bottom: 50px;
    left: 15px;
    right: 15px;
    overflow: auto;

    .controls {
      float: right;
    }
  }
}