@import '../../styles/variables';

.toggleContainer {
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
  margin-top: -15px;

  .option {
    flex: 1 0 auto;

    &:last-child button, &:last-child button:disabled {
      border-right: 0;
    }

    button {
      border-right: 1px solid $button-border;
    }

    button:disabled {
      border: 0;
      border-right: 1px solid $button-border;
    }
  }
}