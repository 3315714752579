@import '../../../styles/variables';

.rule {
  margin: 0 0 25px;
}

.stripe {
  margin: 20px 0 15px;

  .stripeLead {
    margin-bottom: 10px;
  }

  .card {
    border: 1px solid $comp-border;
    padding: 10px;

    &.error { border: 1px solid $error; }
  }
}