@import '../..//styles/variables';

.dashboard {
  max-width: 980px;
  margin: auto;
  padding-top: 25px;

  .section {
    background-color: $white;
    padding: 15px;
    margin-bottom: 25px;

    .sectionHeader {
      font-weight: bold;
      padding: 10px 0 20px;
      border-bottom: 1px solid $border;
    }

    .profile {
      padding: 15px 0;
      border-bottom: 1px solid $border;

      &:last-child { border: 0; padding-bottom: 5px; }

      .profileHeader {
        font-weight: bold;
        padding-bottom: 10px;
      }
    }

    .job {
      padding: 15px 0;
      border-bottom: 1px solid $border;

      &:last-child { border: 0; padding-bottom: 5px; }

      .jobControls {
        margin-top: 10px;
        display: flex;
        justify-content: space-evenly;

        button { margin-right: 10px; }
        button:last-child { margin-right: 0; }
      }

      
      .noJobHeader {
        text-align: center;
      }

      .noJobBody {
        padding-top: 10px;
        text-align: center;
      }
    }
  }
}
