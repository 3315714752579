$background: #eee;
$text: #222;
$error: #dc3545;
$error-text: white;
$success: #155724;
$disabled: #ccc;
$border: #ddd;
$button-border: #bbb;
$blue: #235789;
$white: white;
$tag-background: #ddd;
$tag-border: #bbb;
$modal-background: rgba(0, 0, 0, 0.5);
$footer-background: #111;
$highlight-color: #EDEBA0;
$white: #FDFFFC;
$cta-color: #48759E;
$comp-border: #bbb;
$job-border: #ccc;