@import '../../styles/variables';

.previewLead {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 15px;
}

.listingPreview {
  margin-bottom: 40px;
}

@media (max-width: 980px) {
  .detailPreview { display: none; }
}